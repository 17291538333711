import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout.jsx';
import { doxyme_logo, backgroundColorHeader } from './doxy-me.module.scss';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from "@mui/material/Button";
import { StaticImage } from 'gatsby-plugin-image';
import SEO from "../../components/seo"

const doxyLink = "https://www.doxy.me/"
export const query = graphql`
  query LocalesTNMU($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function DOXY() {
    const translate = useTranslation().t;
    return (
        <Layout>
            <SEO title="doxy.me" />
            <div className={backgroundColorHeader}>
                <StaticImage style={{ margin: "0 auto" }} className={doxyme_logo} src="../../images/doxyme_logo.png" />
            </div>
            <div class="inner" style={{ width: "90%", paddingBottom: "1rem" }}>
                <Typography sx={{ marginTop: "1rem", marginBottom: "3rem", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }} >
                    {translate("doxy-me-description")}
                </Typography>
                <motion.button
                    style={{ border: "none", margin: "auto", display: "flex" }}
                    whileHover={{
                        scale: 1.1,
                    }}
                    whileTap={{ scale: 0.9 }}
                >
                    {
                        <Button href={doxyLink} target="_blank" size="large" variant="contained" color="secondary" >
                            <Typography align="center" color="primary" variant="button">
                                {translate("visit-website")}
                            </Typography>
                        </Button>
                    }
                </motion.button>
            </div>
        </Layout>
    )
}
